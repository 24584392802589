@import "./styles/styles.css";

.lineups {
  overflow: auto;
  background-color: var(--app-bg);
  height: 100%;
  padding-left: 0;
  padding-right: 0;
}

.lineups h1 {
  margin-top: 0;
}

.lr--lineup {
  flex: 4;
}

.lineup-table__headers {
  background-color: var(--blue-stop-3);
  color: white;
}

.lineup-table__legend__text {
  font-size: 0.8rem;
}

.lineup-row:nth-child(2n) {
  background-color: var(--lightest-blue);
}

.trigger {
  height: 0;
  width: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid lightslategray;
}

.trigger-row {
  align-items: center;
}

.trigger-text {
  color: lightslategray;
}

.trigger.trigger--open {
  border-top: none;
  border-bottom: 5px solid lightslategray;
}

.lineup-stints {
  font-size: 0.85rem;
}

.lineup-stints-container {
  max-height: 0;
  transition: 200ms linear;
  overflow: hidden;
  color: lightslategray;
}

.lineup-stints-container.lineup-stints-container--open {
  max-height: 10rem;
}

.lineup-summary > .stint-list {
  display: flex;
}

.lineup-summary__stints-label {
  font-size: 0.85rem;
  color: var(--secondary-text);
}

.lineup-table__count__label {
  visibility: hidden;
  width: 0;
  position: absolute;
}

.lineup-table__count__label.lineup-table__count__label--open {
  visibility: visible;
  width: auto;
  position: unset;
}

.lineup-table__count__btn {
  color: var(--secondary-text);
  font-size: 0.6rem;
  cursor: pointer;
}

.material-symbols-sharp.lineup-table__count__icon {
  font-size: 16px;
  padding-top: 2px;
}

.lineup-table__count__btn:hover > .lineup-table__count__label {
  visibility: visible;
  width: 96px;
}

.lineup-table__count__btn:hover > .lineup-table__count__label.lineup-table__count__label--open {
  width: auto;
}