.circle-badge {
  height: 17px;
  width: 17px;
  border-radius: 17px;
  background-color: var(--navy-blue);
  color: white;
  font-weight: bold;
  font-size: 0.75rem;
  display: inline-block;
  border: 1px solid var(--navy-blue);
  text-align: center;
  flex-shrink: 0;
}

.net.net--pos {
  color: var(--success-1);
  font-weight: bold;
}

.net.net--neg {
  color: var(--error-1);
  font-weight: bold;
}