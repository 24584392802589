.trigger-row {
  cursor: pointer;
}

.stint-title {
  font-weight: bold;
}

.stint-net {
  padding: var(--xs);
  margin-left: var(--xs);
  filter: opacity(0.75);
}

.stint-net.lr--net.lr--net--neg {
  background-color: unset;
}

.stint-net.lr--net.lr--net--pos {
  background-color: unset;
}

.lineup-table__headers {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  box-shadow: 0 0.125rem 0.5rem rgb(0 0 0 / 12%);
}

.starter-badge {
  color: var(--navy-blue);
  background-color: white;
}

.lineup-table__legend {
  text-align: right;
}

.lineup-table__body {
  font-size: 0.9rem;
}

.lineup-table__headers {
  font-weight: bold;
}

.lr--net.lr--net--pos {
  color: var(--success-1);
  font-weight: bold;
  background-color: var(--success-3);
}

.lr--net.lr--net--neg {
  color: var(--error-1);
  background-color: var(--error-3);
  font-weight: bold;
}

.lineup-summary {
  font-size: 0.9rem;
}

.lineup-summary__stat {
  margin-left: var(--s);
  margin-right: var(--s);
}

.lineup-summary__stat__label {
  font-weight: 600;
  margin-right: var(--s);
}